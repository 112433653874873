import styled from "styled-components";
import {FlexColumnStart, StyledButton} from "../../../../style/projectComponents";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {useIsVisible} from "../../../../hooks/useIsVisible";
import {useNavigate} from "react-router-dom";


const RightBottomFigure = (
    <svg className="about-block__body-decor-pc" width="862" height="559" viewBox="0 0 862 559" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M1.00006 558.5H862" stroke="#DEDDD8"></path>
        <path
            d="M662 214C662 251.841 631.86 282.5 595 282.5C558.149 282.5 528.5 251.324 528.5 213.473C528.5 175.635 558.396 145.5 595.259 145.5C632.116 145.5 662 176.156 662 214Z"
            stroke="#DEDDD8"></path>
        <path d="M0.5 0.5H862" stroke="#DEDDD8"></path>
        <path d="M1.00006 1L1.00004 559" stroke="#DEDDD8"></path>
        <path d="M2.00006 282.5H862" stroke="#DEDDD8"></path>
        <path d="M663 283V559" stroke="#DEDDD8"></path>
        <path d="M862 282.5V282.5C752.095 282.5 663 371.595 663 481.5V559" stroke="#DEDDD8"></path>
        <path d="M340 1V558" stroke="#00AAC3"></path>
        <path d="M1.50006 558.5C155.311 558.5 280 433.311 280 279.5C280 125.689 155.311 0.5 1.5 0.5"
              stroke="#00AAC3"></path>
    </svg>
)


const RightFigureWrapper = styled.div<{ lng?: string }>`
  position: absolute;
  right: ${props => props.lng.toLowerCase() === 'ar' ? '' : '-60.625rem'};
  left: ${props => props.lng.toLowerCase() === 'ar' ? '-60.625rem' : ''};
  top: 0.75rem;


  @media (max-width: 900px) {
    position: static;
    margin-top: 40px;
    width: 100%;
  }
`


const Title = styled.h3`
  margin-bottom: 2.5rem;
  text-align: left;
  font-size: 2.125rem;
  font-weight: 300;
  line-height: 110%;
  @media (max-width: 900px) {
    width: min-content;
  }
`

const ConditionsContent = styled(FlexColumnStart)<{ lng?: string }>`
  max-width: 40rem;
  margin-right: ${props => props.lng.toLowerCase() === 'ar' ? '' : 'auto'};
  margin-left: ${props => props.lng.toLowerCase() === 'ar' ? 'auto' : ''};
  position: relative;
`

const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.25rem;
  margin-bottom: 1.25rem;
  margin: 0 -0.625rem;
  box-sizing: border-box;
  padding-left: 12px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const StyledLi = styled.li`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  align-items: start;
  list-style: none;

  span {
    color: #AAB0B8;
    font-size: 1rem;
    line-height: 140%;
  }

  p {
    font-size: 1.125rem;
    line-height: 140%;
  }
`

const Subtitile = styled.p`
  font-size: 1rem;
  max-width: 22.875rem;
  width: 100%;
  margin-top: 1.25rem;
  color: #AAB0B8;
  text-align: start;
  line-height: 140%;

  @media (max-width: 480px) {
    max-width: 17.875rem;
    padding-right: 20px;
    width: auto;
  }
`

const CustomButton = styled(StyledButton)`
  margin-top: 2.5rem;
  font-weight: 300;
  font-size: 1.25rem;
  padding: 1rem 2.5rem;
  border-radius: 4.5rem;
`

const lis = [
    {
        title: 'landing.eith.2.items.1.title',
        value: 'landing.eith.2.items.1.value'
    },
    {
        title: 'landing.eith.2.items.2.title',
        value: 'landing.eith.2.items.2.value'
    },
    {
        title: 'landing.eith.2.items.3.title',
        value: 'landing.eith.2.items.3.value'
    },
    {
        title: 'landing.eith.2.items.4.title',
        value: 'landing.eith.2.items.4.value'
    },
    {
        title: 'landing.eith.2.items.5.title',
        value: 'landing.eith.2.items.5.value'
    },
]

export const Second = () => {

    const {t, i18n} = useTranslation();
    const ref = useRef();
    const isVisible = useIsVisible(ref);
    const navigate = useNavigate();

    return (
        <ConditionsContent lng={i18n.language}>
            <Title>
                {t("landing.eith.2.title")}
            </Title>
            
            <StyledUl>
                {lis.map((it, idx) =>
                    <StyledLi>
                        <span>{t(it.title)}</span>
                        <p>{t(it.value)}</p>
                    </StyledLi>
                    
                    
                )}
            </StyledUl>
            <Subtitile>
                {t("landing.eith.2.subtitle.1")}
            </Subtitile>

            <Subtitile>
                {t("landing.eith.2.subtitle.2")}
            </Subtitile>

            <RightFigureWrapper lng={i18n.language} ref={ref} className={isVisible ? "element-show" : ""}>
                {RightBottomFigure}
            </RightFigureWrapper>
        </ConditionsContent>
    )
}
