import {FlexColumn, FlexColumnStart, StyledButton} from "../../../style/projectComponents";
import {
    MainIllustrationPC,
    MainIllustrationPhone,
    MainIllustrationPhoneSmall,
    MainIllustrationTablet
} from "../MainIllustration";
import React, {FC, useRef} from "react";
import styled from "styled-components";
import {colors} from "../../../style/colors";
import {StyledSection} from "../../global/StyledSection";
import {useTranslation} from "react-i18next";
import {useIsVisible} from "../../../hooks/useIsVisible";
import {useNavigate} from "react-router-dom";


const IllustrationWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 120rem;
  margin-top: -3.625rem;
  box-sizing: border-box;
  z-index: 0;

  .pc, .tablet, .phone, .phone-small {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 62.5em) {
    .pc {
      display: none;
    }
  }
  @media (min-width: 62.5em) {
    .tablet {
      display: none;
    }
  }

  @media (min-width: 47.99875em) {
    .phone {
      display: none;
    }
  }

  @media (max-width: 47.99875em) {
    .tablet {
      display: none;
    }
  }

  @media (max-width: 29.99875em) {
    margin-top: 3.125rem;
    .phone {
      display: none;
    }
  }

  @media (min-width: 29.99875em) {
    .phoneSmall {
      display: none;
    }
  }
`;
const TextWrapper = styled(FlexColumnStart)`
  z-index: 100 !important;
  width: 100%;
  max-width: 81.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  box-sizing: border-box;

  h1 {
    font-weight: 300;
    font-size: clamp(4.125rem, 3.7112068966rem + 0.8620689655vw, 4.25rem);
    max-width: 46.875rem;
    line-height: 105%;
    text-align: start;

    @media (max-width: 480px) {
      font-size: clamp(2.25rem, 1.4998749792rem + 3.3338889815vw, 2.5rem);
    }
  }

  button {
    z-index: 100;
    margin-top: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    border-radius: 4.5rem;

    @media (max-width: 480px) {
      width: 100%;
    }
  }
`;
const MainSectionWrapper = styled(StyledSection)`
  padding-top: 16rem;
  border-left: 0.0625rem solid ${colors.lightGrayBg};
  border-right: 0.0625rem solid ${colors.lightGrayBg};
  align-items: center;
`;

export const MainSection: FC = () => {
    const {t, i18n} = useTranslation();
    const ref = useRef();
    const isVisible = useIsVisible(ref);
    const navigate = useNavigate();

    return (
        <MainSectionWrapper id={'main'}>
            <TextWrapper>
                <h1>{t("landing.first.title")}</h1>
              
            </TextWrapper>
            <IllustrationWrapper ref={ref} className={isVisible ? "element-show" : ""}>
                {MainIllustrationPC}
                {MainIllustrationTablet}
                {MainIllustrationPhone}
                {MainIllustrationPhoneSmall}
            </IllustrationWrapper>
        </MainSectionWrapper>
    );
};
