import {FlexColumnStart, FlexRow} from "../../../style/projectComponents";
import React from "react";
import styled from "styled-components";
import {colors} from "../../../style/colors";
import {StyledSection} from "../../global/StyledSection";
import {useTranslation} from "react-i18next";

const PrinciplesSectionWrapper = styled(StyledSection)`
  border-left: 0.0625rem solid ${colors.lightGrayBg};
  border-right: 0.0625rem solid ${colors.lightGrayBg};
  align-items: center;
  padding-top: 7.25rem;

    @media (min-width: 22.5em) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
`;

const Content = styled(FlexColumnStart)`
  width: 100%;
  max-width: 81.25rem;
  padding: 0 1.25rem;

`

const Title = styled.h2`
  font-weight: 300;
  font-size: 3.125rem;
  text-align: start;


  @media (max-width: 30rem) {
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }

  @media (min-width: 48rem) {
    font-size: 3.125rem;
    margin-bottom: 5rem;
  }

  @media (max-width: 22.5rem) {
    padding-left: 1.25rem;
  }
`

const CardWrapper = styled(FlexRow)`
  row-gap: 1.25rem;
  flex-wrap: wrap;
  margin: 0 -0.625rem;
  align-items: stretch;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

`

const CardLayout = styled(FlexColumnStart)`
  padding: 0 0.625rem;
  flex: 1 1 50%;
  box-sizing: border-box;
  height: auto;
`

const CardIcon = styled.div`
    height: 4.375rem;
    width: 4.375rem;
    margin-bottom: 100px;
    font-size: 3.125rem;
`

const CardContent = styled(FlexColumnStart)`
  padding: 1.8125rem;
  height: 100%;
  border: 0.0625rem solid #DEDDD8;
  box-sizing: border-box;
  text-align: start;
  justify-content: flex-start;


  img {
    margin-bottom: 120px;
    font-size: 2.75rem;
  }

  .text {
    //margin-top: 1.125rem;

    h3 {
      font-weight: 300;
      font-size: 2.125rem;
      line-height: 110%;
    }

    p {
      font-size: 1.125rem;
      margin-top: 0;
      line-height: 140%;
      font-weight: 300;
    }
  }
`

const cards: {
    ico?: string,
    pic?: string,
    title: string,
    text: string
}[] = [
    {
        ico: '_icon-square',
        title: 'landing.second.cards.1.title',
        text: 'landing.second.cards.1.text'
    },
    {
        ico: '_icon-rectangle',
        title: 'landing.second.cards.2.title',
        text: 'landing.second.cards.2.text'
    },
    {
        ico: '_icon-ladder',
        title: 'landing.second.cards.3.title',
        text: 'landing.second.cards.3.text'
    },
    {
        ico: '_icon-schedule',
        title: 'landing.second.cards.4.title',
        text: 'landing.second.cards.4.text'
    },
    {
        pic: 'ico5.svg',
        title: 'landing.second.cards.5.title',
        text: 'landing.second.cards.5.text'
    }
]

export const PrinciplesSection = () => {
    const {t, i18n} = useTranslation();
    return (
        <PrinciplesSectionWrapper
            id={'principles'}>
            <Content>
                <Title>
                    {t("landing.second.principles")}<br/>
                    {t("landing.second.own")}
                </Title>
                <CardWrapper>
                    {cards.map((it, idx) =>
                        <CardLayout>
                            <CardContent>
                                {it.ico ? (<CardIcon className={`${it.ico}`} />) : (<img src={`/landing_media/${it.pic}`}/>)}
                                <FlexColumnStart className={'text'}>
                                    <h3>{t(it.title)}</h3>
                                    <p>{t(it.text)}</p>
                                </FlexColumnStart>
                            </CardContent>
                        </CardLayout>
                    )}
                </CardWrapper>
            </Content>

        </PrinciplesSectionWrapper>
    );
};
