import {FlexColumn, FlexColumnStart, FlexRow} from "../../../../style/projectComponents";
import React from "react";
import styled from "styled-components";
import {colors} from "../../../../style/colors";
import {useTranslation} from "react-i18next";

const StyledStar = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  font-size: 1.5rem;
`
const InvestmentWrapper = styled(FlexColumnStart)`
  gap: 4rem;
  width: 100%;
  max-width: 81.25rem;
  padding: 0 1.25rem;


  h2 {
    text-align: start;
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 105%;
  }

  @media (max-width: 480px) {
    gap: 2rem;
    h2 {
      font-size: 2.125rem;
    }
  }
`
const InvestmentRow = styled(FlexRow)`
  flex-wrap: wrap;
  margin: 0 -0.625rem;
  row-gap: 1.25rem;
  width: 100%;

  @media (min-width: 760px) {
    .idx1 {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }
  }
`

const InvestmentCard = styled(FlexColumnStart)`
  flex: 1 1 33%;
  width: 100%;
  @media (max-width: 760px) {
    padding: 0 0.625rem;
  }
  box-sizing: border-box;
`

const InvestmentCardContent = styled(FlexColumnStart)`
  padding: 1.875rem;
  min-height: 19rem;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 0.0625rem solid #DEDDD8;
`

const InvestmentContent = styled(FlexColumn)`
  padding-top: 11rem;
  width: 100%;
  max-width: 120rem;
  align-items: center;
  position: relative;
  border-left: 0.0625rem solid ${colors.lightGrayBg};
  border-right: 0.0625rem solid ${colors.lightGrayBg};
  padding-bottom: 7.5rem;
  padding-left: 1.25rem;
  box-sizing: border-box;

`
const items = [
    {
        stars: 3,
        title: 'landing.third.1.cards.1.title',
        percent: 50,
    },
    {
        stars: 3,
        title: 'landing.third.1.cards.2.title',
        percent: 25,
    },
    {
        stars: 3,
        title: 'landing.third.1.cards.3.title',
        percent: 25,
    },
]

const TextContent = styled(FlexRow)`
  gap: 12px;
  align-items: baseline;
  box-sizing: border-box;

  h3 {
    font-size: 2.125rem;
    line-height: 110%;
    font-weight: 300;
  }

  p {
    font-size: 1.125rem;
    line-height: 140%;
    font-weight: 300;
  }

  @media (max-width: 1100px) {
    align-items: center;
  }
`

const Title = styled.h3`
  text-align: left;
  width: 100%;
  font-size: 1.25rem;
  line-height: 110%;
  font-weight: 300;
`
export const First = () => {
    const {t} = useTranslation();

    return (
        <InvestmentContent>
            <InvestmentWrapper>
                <h2>
                    {t("landing.third.1.title.invest")}
                    <br/>
                    {t("landing.third.1.title.strategy")}
                </h2>
                <InvestmentRow>
                    {items.map((it, idx) =>
                        <InvestmentCard className={`idx${idx}`}>
                            <InvestmentCardContent>
                                <FlexRow style={{gap: '1rem'}}>
                                    {Array.from(Array(it.stars), (x) => x).map((it, idx) =>
                                        <StyledStar className="_icon-star"/>
                                    )}
                                </FlexRow>
                                <Title>{t(it.title)}</Title>
                                <TextContent>
                              
                                </TextContent>
                            </InvestmentCardContent>
                        </InvestmentCard>
                    )}
                </InvestmentRow>
            </InvestmentWrapper>
        </InvestmentContent>
    );
};
