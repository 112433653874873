import {FlexRowBetween, LanguageSwitch} from "../../style/projectComponents";
import React, {ComponentPropsWithoutRef, FC, useContext, useState} from "react";
import LanguageStorage from "../../storage/LanguageStorage/LanguageStorage";
import UserStorage from "../../storage/UserStorage/UserStorage";
import i18n from "../../config/i18n";
import styled from "styled-components";

const LanguageWrapper = styled(FlexRowBetween)`
  //width: 70px;
  column-gap: 2rem;
`;
export const ChangeLanguage: FC<ComponentPropsWithoutRef<'div'>> = (props) => {
    const {...other} = props;

    const LanguageStore = useContext(LanguageStorage);
    const UserStore = useContext(UserStorage);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const [langs, setLangs] = useState([
        {
            lang: 'en',
            active: true
        },
        {
            lang: 'ru',
            active: false
        }

    ].map((it, idx) => LanguageStore.language === it.lang
        ? {...it, active: true}
        : {...it, active: false}
    ));

    return (
        <LanguageWrapper {...other}>
            {langs.map((it, idx) =>
                <LanguageSwitch
                    onClick={() => {
                        setLangs(langs.map((lng) =>
                            lng.lang === it.lang
                                ? {...lng, active: true}
                                : {...lng, active: false})
                        );
                        changeLanguage(it.lang);
                        LanguageStore.setLanguage(it.lang);
                    }}
                    active={it.active}>
                    {it.lang}
                </LanguageSwitch>
            )}
        </LanguageWrapper>
    );
};